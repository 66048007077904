import { createRouter, createWebHistory } from 'vue-router'
import { useAuth } from '../stores/modules/authentication'
import { useUtilities } from '../stores/modules/utilities'
import { h } from 'vue'
import { RouterView } from 'vue-router'
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });

const Login = () => import('@/views/LoginView.vue');

async function dynamicShiftsPageValidator(to, from, next) {
  if (Object.keys(useUtilities().organizationFeatures).length == 0) await useUtilities().getSidebarParams();
  let orgHasDynamicShifts = useUtilities().organizationFeatures.dynamic_shifts;

  if (orgHasDynamicShifts) next();
  else next('/dashboard');
}

async function withdrawOnlyFeatureValidator(to, from, next) {
  if (Object.keys(useUtilities().organizationFeatures).length == 0) await useUtilities().getSidebarParams();
  let withdrawalOnly = useUtilities().organizationFeatures.withdrawals_only == true;

  if (withdrawalOnly) next();
  else next('/dashboard');
}

async function payCyclePageValidator(to, from, next) {
  if (Object.keys(useUtilities().organizationFeatures).length == 0) await useUtilities().getSidebarParams();
  let withdrawalOnly = useUtilities().organizationFeatures.withdrawals_only == true;

  if (!withdrawalOnly) next();
  else next('/dashboard');
}

async function biometricsAttendancePageValidator(to, from, next) {
  if (!useUtilities().isGovernment) await useUtilities().getSidebarParams();
  let isGovernment = useUtilities().isGovernment;

  if (isGovernment) next();
  else next('/dashboard');
}

async function dailyAttendancePageValidator(to, from, next) {
  if (!useUtilities().isGovernment) await useUtilities().getSidebarParams();
  let isGovernment = useUtilities().isGovernment;

  if (!isGovernment) next();
  else next('/dashboard');
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', redirect: '/login' },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { title: 'Login | EveryPay Dashboard' },
    },
    {
      path: "/set-password",
      name: 'SetPassword',
      component: () => import('@/views/SetPassword.vue'),
    },
    {
      path: "/set-password-success",
      name: 'SetPasswordSuccess',
      component: () => import('@/views/SetPasswordSuccess.vue'),
    },
    {
      path: '/dashboard',
      component: () => import('@/components/shared/TheSidebar.vue'),
      redirect: { name: 'EmployeeList' },
      children: [
        {
          path: 'employees',
          component: { render: () => h(RouterView) },
          children: [
            {
              path: '',
              name: 'EmployeeList',
              meta: { title: 'Employees | EveryPay Dashboard' },
              component: () => import('@/views/EmployeeList.vue')
            },
            {
              path: ':id',
              name: 'EmployeeShow',
              props: true,
              meta: { title: 'Employees | EveryPay Dashboard' },
              component: () => import('@/views/EmployeeShow.vue')
            },
            {
              path: 'new',
              name: 'EmployeeNew',
              props: true,
              meta: { title: 'New Employee | EveryPay Dashboard' },
              component: () => import('@/views/EmployeeForm.vue')
            },
            {
              path: ':id/edit',
              name: 'EmployeeEdit',
              props: true,
              meta: { title: 'Edit Employee | EveryPay Dashboard' },
              component: () => import('@/views/EmployeeForm.vue')
            },
          ]
        },
        {
          path: 'requests',
          name: 'RequestsList',
          meta: { title: 'For Approval | EveryPay Dashboard' },
          component: () => import('@/views/RequestsList.vue')
        },
        {
          path: 'attendance',
          component: { render: () => h(RouterView) },
          beforeEnter: dailyAttendancePageValidator,
          children: [
            {
              path: '',
              name: 'AttendanceList',
              meta: { title: 'Attendance | EveryPay Dashboard' },
              component: () => import('@/views/AttendanceList.vue')
            },
            {
              path: 'missing',
              name: 'MissingAttendanceList',
              meta: { title: 'Attendance | EveryPay Dashboard' },
              component: () => import('@/views/MissingAttendanceList.vue')
            }
          ]
        },
        {
          path: 'biometrics',
          component: { render: () => h(RouterView) },
          beforeEnter: biometricsAttendancePageValidator,
          children: [
            {
              path: '',
              name: 'BiometricsTable',
              meta: { title: 'Attendance | EveryPay Dashboard' },
              component: () => import('@/views/BiometricsTable.vue')
            }
          ]
        },
        {
          path: 'shifts',
          component: { render: () => h(RouterView) },
          beforeEnter: dynamicShiftsPageValidator,
          children: [
            {
              path: '',
              name: 'ShiftList',
              meta: { title: 'Shifts | EveryPay Dashboard' },
              component: () => import('@/views/ShiftList.vue')
            },
            {
              path: 'edit',
              name: 'ShiftGroupEdit',
              meta: { title: 'Edit Shifts | EveryPay Dashboard' },
              component: () => import('@/views/ShiftGroupEdit.vue')
            },
          ]
        },
        {
          path: 'pay-cycles',
          component: { render: () => h(RouterView) },
          beforeEnter: payCyclePageValidator,
          children: [
            {
              path: 'settings',
              name: 'PayCycleSettings',
              meta: { title: 'Pay Cycle | EveryPay Dashboard' },
              component: () => import('@/views/PayCycleSettings.vue')
            },
            {
              path: '',
              name: 'PayCycleList',
              meta: { title: 'Pay Cycle | EveryPay Dashboard' },
              component: () => import('@/views/PayCycleList.vue')
            },
            {
              path: ':id',
              name: 'PayCycleShow',
              component: () => import('@/views/PayCycleShow.vue'),
              meta: { title: 'PayCycle | EveryPay Dashboard' },
              redirect: { name: 'PayCycleSubmit' },
              props: true,
              children: [
                {
                  path: 'disbursements',
                  name: 'PayCycleDisbursement',
                  meta: { title: 'PayCycle | EveryPay Dashboard' },
                  component: () => import('@/views/PayCycleDisbursement.vue')
                },
                {
                  path: 'finalize',
                  name: 'PayCycleFinalize',
                  meta: { title: 'PayCycle | EveryPay Dashboard' },
                  component: () => import('@/views/PayCycleFinalize.vue')
                },
                {
                  path: 'submit',
                  name: 'PayCycleSubmit',
                  meta: { title: 'PayCycle | EveryPay Dashboard' },
                  component: () => import('@/views/PayCycleSubmit.vue')
                },
              ]
            },
          ]
        },
        {
          path: 'withdrawals',
          beforeEnter: withdrawOnlyFeatureValidator,
          children: [
            {
              path: '',
              name: 'WithdrawalList',
              meta: { title: 'Withdrawals | EveryPay Dashboard' },
              component: () => import('@/views/WithdrawalList.vue')
            },
            {
              path: ':id',
              props: true,
              name: 'WithdrawalShow',
              meta: { title: 'Withdrawals | EveryPay Dashboard' },
              component: () => import('@/views/WithdrawalShow.vue')
            }
          ]
        },
      ]
    }
  ]
})

router.beforeEach(async (to) => {
  document.title = to.meta.title || "EveryPay Dashboard";

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/'];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuth();
  if (authRequired && !authStore.isAuthenticated) {
    authStore.returnUrl = to.fullPath;
    return '/login';
  } else if (!authRequired && authStore.isAuthenticated) {
    authStore.returnUrl = to.fullPath;
    return '/dashboard/employees';
  }
});

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
    window.location = to.fullPath
  }
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
